import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import {
  ClerkAction,
  clerkNamespace,
  ClerkOrdersGet,
  ClerkState,
  dispatchClerkAction
} from '../../_state/clerk-module-type';

// Filters
import { date } from '@/filters/date.filter';
import NwdLocations from '@/router/nwd-router';
import {
  STATUS_ORDER_ASSIGNMENT,
  STATUS_PROGRESS_ORDER_STRING
} from '@/shared/constants/common';
import {
  getFormatStringDate,
  getFormatStringHour
} from '@/utilities/datetime-converter';
import moment from 'moment-timezone';

// Components
import { OrderListOutput, AmsOrderStatusEnum } from '@/api/api';
import eventBus from '@/eventBus';
import ClerkOrderListFilter from './ClerkOrderListFilter.vue';
import { Location } from 'vue-router';

@Component({
  name: 'OrderList',
  components: {
    ClerkOrderListFilter
  },
  filters: {
    date
  }
})
export default class ClerkOrderList extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;

  headers = [
    {
      text: 'AMS Order #',
      value: 'amsOrderId',
      sortable: true
    },
    { text: 'Assignment Status', value: 'progressStatus', sortable: true },
    { text: 'Progress Status', value: 'orderStatusCode', sortable: true },
    { text: '# Line Items', value: 'lineItemCount', sortable: true },
    { text: 'Loc.', value: 'locationCode', sortable: true },
    { text: '# Cases', value: 'numberOfCases', sortable: true },
    { text: 'Ship To Customer', value: 'customer', sortable: true },
    { text: 'Storage Customer', value: 'wineryName', sortable: true },
    { text: 'AMS Ship Date', value: 'expectedShipDate', sortable: true },
    { text: '', value: 'actions', sortable: false }
  ];
  STATUS_ORDER_ASSIGNMENT = STATUS_ORDER_ASSIGNMENT;
  ORDER_STATUS_RETURNED = AmsOrderStatusEnum.Returned;
  localtz = moment.tz.guess();

  @clerkNamespace.State(ClerkState.orders)
  readonly orders!: OrderListOutput[];
  @clerkNamespace.State(ClerkState.orderCount)
  readonly orderCount!: number;
  @clerkNamespace.State(ClerkState.loading)
  readonly loading!: boolean;
  @clerkNamespace.State(ClerkState.clerkOrdersGet)
  readonly clerkOrdersGet!: ClerkOrdersGet;

  @Inject('assignOrderFunc')
  assignOrder!: (order: OrderListOutput) => void;

  async mounted(): Promise<void> {
    await this.loadOrders();
  }

  created(): void {
    eventBus.$on('orderAssigned', this.loadOrders);
  }

  getExpectedShipDate(item: OrderListOutput): string {
    // make sure to ignore TZ info, since this is a date only
    const momentDate = moment(item.expectedShipDate).tz('UTC');
    return momentDate.format('M/D/YYYY');
  }

  getProcessStatusDetail(item: OrderListOutput): string {
    const status = `${STATUS_PROGRESS_ORDER_STRING[item.orderStatusCode]} @`;
    let content = '';

    if (item.orderStatusCode === AmsOrderStatusEnum.Returned) {
      content += item.interrupter ? `${item.interrupter} ` : '';
    }

    if (item.orderStatusCode !== AmsOrderStatusEnum.NotStarted)
      content += getFormatStringHour(item.progressDate as Date, this.localtz);

    if (item.orderStatusCode !== AmsOrderStatusEnum.NotStarted) content = `(${content})`;

    return status.replace('@', content);
  }

  getAssignmentStatusDetail(item: OrderListOutput): string | undefined {
    if (!item.pickerId || item.orderStatusCode === AmsOrderStatusEnum.Returned) {
      return `${STATUS_ORDER_ASSIGNMENT.unassigned} `;
    }
    // item.clerkNote
    // item.lineItemNotes
    return `${item.pickerName}`;
  }

  navigateToDetails(item: OrderListOutput): void {
    const location = this.getOrderDetailLocation(item.orderId);
    this.$router.push(location);
  }

  getOrderDetailLocation(orderId: number): Location {
    return NwdLocations.clerk.orderDetail.detail(orderId);
  }

  async updateFilter(options: Partial<ClerkOrdersGet>): Promise<void> {
    if (
      options.itemsPerPage === this.clerkOrdersGet.itemsPerPage &&
      options.page === this.clerkOrdersGet.page &&
      options.sortBy === this.clerkOrdersGet.sortBy &&
      options.sortDesc === this.clerkOrdersGet.sortDesc
    ) {
      return;
    }
    console.log('updateFilter', options);
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: options
    });
  }

  private async loadOrders() {
    await dispatchClerkAction(ClerkAction.getOrders);
  }

  private destroyed(): void {
    eventBus.$off('orderAssigned', this.loadOrders);
  }
}
